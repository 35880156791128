<template>
  <v-card elevation="1" class="pa-2 relative">
    <div class="d-flex justify-space-between">
      <v-chip
        class="ma-2"
        color="primary"
        outlined
        @click="showDetails = !showDetails"
      >
        <strong>{{ transactionEdited.type }}</strong> &nbsp;
        {{ compDateCreated }}
      </v-chip>
      <div class="d-flex justify-end">
        <v-btn
          v-if="compShowRefundButton"
          small
          color="primary"
          icon
          @click="
            showRefundTransactionConfirmation = true;
            showEditButtons = false;
          "
        >
          <v-icon small>mdi-cash-refund</v-icon>
        </v-btn>

        <v-btn
          v-if="compShowPseudoRefundButton"
          small
          color="primary"
          icon
          @click="
            showPseudoRefundTransactionConfirmation = true;
            showEditButtons = false;
          "
        >
          <v-icon small>mdi-cash-refund</v-icon>
        </v-btn>

        <v-btn
          v-if="compShowEditButton"
          small
          color="primary"
          icon
          @click="editTransaction"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          v-if="showDetails === false"
          small
          color="primary"
          icon
          @click="showDetails = true"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          v-if="showDetails === true"
          small
          color="primary"
          icon
          @click="showDetails = false"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </div>
    </div>

    <v-row v-if="showDetails === true">
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          disabled
          label="Transaction No."
          v-model="transactionEdited._id"
        ></v-text-field>
      </v-col>

      <v-col v-if="transactionEdited.dateStart" cols="12" sm="6" md="3">
        <v-menu
          v-model="dateStartMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
          clearable
          dense
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="compDateStart"
              label="Start Date"
              readonly
              v-bind="attrs"
              v-on="on"
              :disabled="!showEditButtons"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            v-model="compDateStart"
            @input="dateStartMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col v-if="transactionEdited.dateEnd" cols="12" sm="6" md="3">
        <v-menu
          v-model="dateEndMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
          clearable
          dense
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="compDateEnd"
              label="End Date"
              readonly
              v-bind="attrs"
              v-on="on"
              :disabled="!showEditButtons"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            v-model="compDateEnd"
            @input="dateEndMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col v-if="transactionEdited.wht" cols="12" sm="6" md="3">
        <v-switch
          disabled
          label="WHT"
          v-model="transactionEdited.wht"
        ></v-switch>
      </v-col>

      <v-col v-if="transactionEdited.paymentMethod" cols="12" sm="6" md="3">
        <v-select
          label="Payment Method"
          v-model="transactionEdited.paymentMethod"
          :items="paymentMethods"
          :disabled="!showEditButtons"
        ></v-select>
      </v-col>

      <v-col v-if="transactionEdited.cardDetails" cols="12" sm="6" md="3">
        <v-text-field
          disabled
          label="Card Details"
          v-model="transactionEdited.cardDetails"
        ></v-text-field>
      </v-col>

      <v-col v-if="transactionEdited.amount" cols="12" sm="6" md="3">
        <v-text-field
          :disabled="!showEditButtons"
          label="Subtotal"
          v-model="transactionEdited.amount"
        ></v-text-field>
      </v-col>

      <v-col v-if="transactionEdited.discount" cols="12" sm="6" md="3">
        <v-text-field
          :disabled="!showEditButtons"
          label="Discount"
          v-model="transactionEdited.discount"
        ></v-text-field>
      </v-col>

      <v-col v-if="transactionEdited.grandTotal" cols="12" sm="6" md="3">
        <v-text-field
          disabled
          label="Total"
          v-model="compGrandTotal"
        ></v-text-field>
      </v-col>

      <v-col
        v-if="typeof transactionEdited.isSubscription !== 'undefined'"
        cols="12"
        sm="6"
        md="3"
      >
        <v-switch
          disabled
          label="Subscription?"
          v-model="transactionEdited.isSubscription"
        ></v-switch>
      </v-col>

      <v-col v-if="transactionEdited.subscriptionPrice" cols="12" sm="6" md="3">
        <v-text-field
          disabled
          label="Subscription Price"
          v-model="transactionEdited.subscriptionPrice"
        ></v-text-field>
      </v-col>

      <v-col v-if="transactionEdited.isRefunded" cols="12" sm="6" md="3">
        <v-text-field
          disabled
          label="Refunded"
          v-model="transactionEdited.isRefunded"
        ></v-text-field>
      </v-col>

      <v-col v-if="transactionEdited.notes" cols="12">
        <v-textarea
          disabled
          label="Notes"
          v-model="transactionEdited.notes"
        ></v-textarea>
      </v-col>

      <v-col
        v-if="transactionEdited.type === 'Payment Link Purchase'"
        cols="12"
      >
        Payment Link Services
        <v-btn
          icon
          x-small
          color="primary"
          :disabled="!showEditButtons"
          @click="
            transactionEdited.paymentlink.lines.push({
              service: '',
              notes: '',
              quantity: 0,
              price: 0
            })
          "
          ><v-icon>mdi-plus-circle</v-icon></v-btn
        >
        <table v-if="transactionEdited.paymentlink">
          <tr>
            <td><label for="">Service</label></td>
            <td><label for="">Notes</label></td>
            <td><label for="">Quantity</label></td>
            <td><label for="">Price</label></td>
            <td><label for="">Subtotal</label></td>
            <td><label for=""></label></td>
          </tr>
          <tr v-for="line in transactionEdited.paymentlink.lines">
            <td>
              <v-select
                :disabled="!showEditButtons"
                :items="paymentLinkServices"
                v-model="line.service"
              ></v-select>
            </td>
            <td>
              <v-text-field
                :disabled="!showEditButtons"
                v-model="line.notes"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                :disabled="!showEditButtons"
                v-model="line.quantity"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                :disabled="!showEditButtons"
                v-model="line.price"
              ></v-text-field>
            </td>
            <td>{{ multiply(line.quantity, line.price) }}</td>
            <td>
              <v-btn
                v-if="showEditButtons"
                color="primary"
                icon
                x-small
                @click="transactionEdited.paymentlink.lines.splice(index, 1)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>

          <tr>
            <td colspan="4"><label for="">Total</label></td>
            <td colspan="1">
              <label for="">{{
                total(transactionEdited.paymentlink.lines)
              }}</label>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>

    <v-row v-if="showEditButtons">
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-btn
            color="primary"
            small
            class="mx-2"
            outlined
            @click="cancelEditTransaction"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            small
            class="mx-2"
            depressed
            @click="saveTransaction"
            >Save</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <v-row v-if="showRefundTransactionConfirmation">
      <v-col cols="12">
        <div class="d-flex justify-end">
          You are about to refund this transaction. Do you wish to proceed?
          <v-btn
            color="primary"
            small
            class="mx-2"
            outlined
            @click="showRefundTransactionConfirmation = false"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            small
            class="mx-2"
            depressed
            @click="refundTransaction"
            >Yes</v-btn
          >
        </div>
      </v-col>
    </v-row>

    

        <v-row v-if="showPseudoRefundTransactionConfirmation">
      <v-col cols="12">
        <div class="d-flex justify-end">
          You are about to refund this transaction. Do you wish to proceed?
          <v-btn
            color="primary"
            small
            class="mx-2"
            outlined
            @click="showPseudoRefundTransactionConfirmation = false"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            small
            class="mx-2"
            depressed
            @click="refundPseudoTransaction"
            >Yes</v-btn
          >
        </div>
      </v-col>
    </v-row>


  </v-card>
</template>

<script>
const moment = require("moment");
const axios = require("axios");
const _ = require("lodash");
axios.defaults.withCredentials = true;
export default {
  name: "transaction-card",
  data() {
    return {
      dateStartMenu: false,
      dateEndMenu: false,
      showEditButtons: false,
      showRefundTransactionConfirmation: false,
      showPseudoRefundTransactionConfirmation: false,
      showDetails: false,
      paymentMethods: [
        "Bank Transfer",
        "Cash",
        "EDC",
        "OPN Credit Card",
        "Omise"
      ],
      paymentLinkServices: [
        "Storage service",
        "Door-to-Door service",
        "Meeting room service",
        "Document service",
        "Padlock removal service",
        "Other service"
      ],
      transactionOriginal: {},
      transactionEdited: {
        isRefunded: false,
        paymentlink: {
          lines: [
            {
              service: "",
              notes: "",
              quantity: 0,
              price: 0
            }
          ]
        }
      }
    };
  },
  props: {
    transaction: Object
  },
  methods: {
    multiply(x, y) {
      return Number(x) * Number(y);
    },
    total(lines) {
      let total = 0;
      if (lines) {
        lines.forEach(
          l => (total = total + this.multiply(l.quantity, l.price))
        );
      }
      return total;
    },
    hideEmpty(d) {
      // console.log(d)
      // console.log(typeof(d))

      // if(typeof(d)!=='undefined') return d
      if (typeof d === "boolean") return String(d);
    },
    formatThaiTime(d) {
      return moment
        .utc(d)
        .local()
        .format("DD MMM YYYY HH:mm:ss (Z)");
    },
    formatThaiDate(d) {
      return moment
        .utc(d)
        .local()
        .format("DD MMM YYYY");
    },
    editTransaction(item) {
      this.showDetails = true;
      // create a snapshot of the original transaction
      // this.transactionOriginal = JSON.parse(JSON.stringify(this.transaction));
      // create an immutable copy of the transaction
      // Create an immutable copy of the object
      // const transactionOriginal = Object.assign({}, this.transaction);
      this.transactionOriginal = _.cloneDeep(this.transaction);

      this.showEditButtons = !this.showEditButtons;
    },
    cancelEditTransaction() {
      this.showEditButtons = false;
      this.transactionEdited = _.cloneDeep(this.transactionOriginal);
      // this.transactionEdited = JSON.parse(
      //   JSON.stringify(this.transactionOriginal)
      // );
    },
    refundTransaction() {
      let vm = this;
      let refund = {
        id: vm.transactionEdited._id,
        amount: vm.transactionEdited.amount,
        customerID: vm.transactionEdited.customerID
      };
      axios
        .post(vm.$root.urls.api + "/omise/refund", refund)
        .then(res => {
          vm.transaction = Object.assign({}, vm.transaction, {
            isRefunded: true
          });
          this.showRefundTransactionConfirmation = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    
refundPseudoTransaction() {
      let vm = this;
      let refund = {
        id: vm.transactionEdited._id,
        amount: vm.transactionEdited.amount,
        customerID: vm.transactionEdited.customerID
      };
      axios
        .post(vm.$root.urls.api + "/omise/pseudo/refund", refund)
        .then(res => {
          vm.transaction = Object.assign({}, vm.transaction, {
            isRefunded: true
          });
          this.showPseudoRefundTransactionConfirmation = false;
        })
        .catch(err => {
          console.log(err);
        });
    },

    saveTransaction() {
      let vm = this;
      let transaction = {
        originalTransaction: vm.transactionOriginal,
        editedTransaction: vm.transactionEdited
      };
      console.log(transaction);
      axios
        .put(vm.$root.urls.api + "/booking/transaction", transaction)
        .then(res => {
          console.log(res);
          this.showEditButtons = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    formatCalendarFriendlyDate(d) {
      return moment(d).format("YYYY-MM-DD");
    }
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },

    compShowEditButton() {
      // user roles. Only admin can edit
      if (this.role !== "Admin") return false;
      // limit to certain transaction types
      if (String(this.transactionEdited.type).includes("Booking created by"))
        return true;
      if (String(this.transactionEdited.type).includes("Extended by"))
        return true;
      // if (String(this.transaction.type).includes("Edited by"))
      //   return true;
      if (String(this.transactionEdited.type).includes("Payment Link Purchase"))
        return true;
      return false;
    },
    compShowRefundButton() {
      // user roles. Only admin, head and cheif can edit
      if (!["Admin", "Head", "Chief"].includes(this.role)) return false;

      // can only refund if not already refunded
      if (this.transaction.isRefunded) return false;

      // limit to certain payment methods:
      if (
        !["OPN Credit Card", "Omise"].includes(
          this.transactionEdited.paymentMethod
        )
      ) {
        return false;
      }

      // limit to certain transaction types
      if (String(this.transactionEdited.type).includes("Booking created"))
        return true;
      if (String(this.transactionEdited.type).includes("scheduled payment"))
        return true;
      if (String(this.transactionEdited.type).includes("Extended booking"))
        return true;
      if (String(this.transactionEdited.type).includes("Payment Link Purchase"))
        return true;
      return false;
    },
    compShowPseudoRefundButton() {
      // user roles. Only admin, head and cheif can edit
      if (!["Admin", "Head", "Chief"].includes(this.role)) return false;

      // can only refund if not already refunded
      if (this.transaction.isRefunded) return false;

      // limit to certain payment methods:
      /* if (
        !["OPN Credit Card", "Omise"].includes(
          this.transactionEdited.paymentMethod
        )
      ) {
        return false;
      } */

      // limit to certain transaction types
      if (String(this.transactionEdited.type).includes("Booking created by"))
        return true;
      if (
        String(this.transactionEdited.type).includes(
          "Admin changed billing date"
        )
      )
        return true;
      if (String(this.transactionEdited.type).includes("Extended by"))
        return true;

      return false;
    },

    compDateStart: {
      get() {
        return moment(this.transactionEdited.dateStart).format("YYYY-MM-DD");
      },
      set(val) {
        console.log(val);
        this.transactionEdited.dateStart = val;
      }
    },
    compDateEnd: {
      get() {
        return moment(this.transactionEdited.dateEnd).format("YYYY-MM-DD");
      },
      set(val) {
        console.log(val);
        this.transactionEdited.dateEnd = val;
      }
    },
    compDateCreated() {
      return this.formatThaiTime(this.transactionEdited.dateCreated);
    },
    compGrandTotal() {
      const amount = this.transactionEdited.amount;
      const discount = this.transactionEdited.discount
        ? this.transactionEdited.discount
        : 0;
      return Number(amount) - Number(discount);
    }
  },
  mounted() {
    // make a copy of the transaction
    // this.transactionEdited = JSON.parse(JSON.stringify(this.transaction));
    this.transactionEdited = _.cloneDeep(this.transaction);

    // if this is a payment link purchase, get the details
    if (this.transactionEdited.type === "Payment Link Purchase") {
      let vm = this;
      axios
        .get(
          vm.$root.urls.api + "/v3/paymentLink/id/" + vm.transactionEdited.notes
        )
        .then(res => {
          vm.transactionOriginal.cardDetails = `${res.data.charge.charges.data[0].card.brand} ${res.data.charge.charges.data[0].card.last_digits}`;
          vm.transactionEdited.cardDetails = `${res.data.charge.charges.data[0].card.brand} ${res.data.charge.charges.data[0].card.last_digits}`;

          const paymentLinkData = res.data;

          vm.transactionOriginal = Object.assign({}, vm.transactionOriginal, {
            paymentlink: paymentLinkData
          });

          vm.transactionEdited = Object.assign({}, vm.transactionEdited, {
            paymentlink: paymentLinkData
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  /*    margin-bottom: 20px;*/
}

table label {
  font-weight: bold;
}
table tr td:nth-child(1) {
  width: 20%;
}
table tr td:nth-child(2) {
  width: 30%;
}
table tr td:nth-child(3) {
  width: 20%;
}
table tr td:nth-child(4) {
  width: 30%;
}
</style>
