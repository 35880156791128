<template>
  <v-container
    fluid
    class="primary"
    style="position: fixed; min-height: 100vh; overflow-y: scroll"
  >
    <v-row style="height: 100vh" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-12">
          <v-btn
            @click="showNewBookingModal = true"
            absolute
            mr-0
            class=""
            outlined
            color="primary"
            style="position: absolute; top: 10px; right: 10px;"
            >NEW Booking</v-btn
          >
          <v-card-title>Bookings</v-card-title>
          <vue-json-to-csv
            v-if="role === 'Admin'"
            :json-data="compBookingsCSV"
            :csv-title="'bookings'"
          >
            <v-btn
              color="primary"
              outlined
              class="mb-2"
              style="position: absolute; top: 10px; right: 180px;"
            >
              Download
            </v-btn>
          </vue-json-to-csv>
          <v-card-subtitle>Enter a booking No. to filter</v-card-subtitle>
          <v-card-title>
            <v-row fluid>
              <v-col cols="12" sm="3" md="2">
                <v-select
                  v-model="searchLocation"
                  :items="searchLocations"
                  label="Location"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-menu
                  ref="menu"
                  v-model="dateSearchBookingMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dateSearchBooking"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateSearchBookingText"
                      label="Booking Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="primary"
                    no-title
                    range
                    v-model="dateSearchBooking"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dateSearchBooking = []">
                      Reset
                    </v-btn>

                    <v-btn
                      text
                      color="primary"
                      @click="dateSearchBookingMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="$refs.menu.save(dateSearchBooking)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-menu
                  ref="menuEnd"
                  v-model="dateSearchEndMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dateSearchEnd"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateSearchEndText"
                      label="End Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="primary"
                    no-title
                    range
                    v-model="dateSearchEnd"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dateSearchEnd = []">
                      Reset
                    </v-btn>

                    <v-btn
                      text
                      color="primary"
                      @click="dateSearchEndMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="$refs.menuEnd.save(dateSearchEnd)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-text-field
                  v-model="searchOmise"
                  append-icon="mdi-magnify"
                  label="Transaction No."
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-text-field
                  v-model="searchCustomer"
                  label="Customer Name"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-switch
                  v-model="showInactive"
                  label="Show Inactive?"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-subtitle>
            <v-row>
              <v-col cols="12" sm="3" md="2" class="">
                <strong>Total bookings: </strong>{{ compAnalytics.total }}
              </v-col>
              <v-col cols="12" sm="3" md="2" class="">
                <strong>Subscription: </strong>{{ compAnalytics.subscription }}
              </v-col>
              <v-col cols="12" sm="3" md="2" class="">
                <strong>Yearly: </strong>{{ compAnalytics.yearly }}
              </v-col>
              <v-col cols="12" sm="3" md="2" class="">
                <strong>Half Yearly: </strong>{{ compAnalytics.halfYearly }}
              </v-col>
              <v-col cols="12" sm="3" md="2" class="">
                <strong>Monthly: </strong>{{ compAnalytics.monthly }}
              </v-col>
              <v-col cols="12" sm="3" md="2" class="">
                <strong>Weekly: </strong>{{ compAnalytics.weekly }}
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="compBookings"
            item-key="_id"
            :expanded.sync="expanded"
            show-expand
          >
            <!-- :search="search" -->
            <template v-slot:item.customerDetails="{ item }">
              <router-link v-bind:to="'/users/' + item.customerDetails._id"
                >{{ item.customerDetails.firstname }}
                {{ item.customerDetails.lastname }}</router-link
              >
            </template>
            <template v-slot:item.isSubscription="{ item }">
              <v-icon v-if="item.isSubscription" small>mdi-check-bold</v-icon>
            </template>
            <template v-slot:item.createdOn="{ item }">
              <FancyDate :date="item.createdOn" />
            </template>
            <template v-slot:item.dateStart="{ item }">
              <FancyDate :date="item.dateStart" />
            </template>
            <template v-slot:item.dateEnd="{ item }">
              <FancyDate :date="item.dateEnd" />
            </template>
            <template v-slot:item.unitDetails="{ item }">
              <router-link v-bind:to="'/Units/' + item.unitDetails._id">{{
                item.unitDetails.unitNumber
              }}</router-link>
              {{ item.unitDetails.type }}
            </template>
            <template v-slot:item._id="{ item }">
              <v-btn icon @click="emitShowEditBookingModal(item)">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.notes="{ item }">
              <v-tooltip v-if="item.notes" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-comment-text
                  </v-icon>
                </template>
                <span>{{ item.notes }}</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-flex xs12>
                  <TransactionCard
                    v-for="transaction in item.transactions"
                    :key="transaction._id"
                    :index="transaction._id"
                    :transaction="transaction"
                    class="my-3"
                  />
                </v-flex>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- NEW BOOKING MODAL -->
    <v-dialog v-model="showNewBookingModal" width="800" persistent>
      <v-card>
        <v-card-title class="primary white--text text-h5">
          NEW BOOKING
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col sm="12" md="4">
                <v-autocomplete
                  v-model="newBooking.customer"
                  :items="compUsers"
                  label="Customer"
                  item-text="fullname"
                  item-value="_id"
                  persistent-hint
                >
                </v-autocomplete>
              </v-col>
              <v-col sm="12" md="4">
                <v-text-field
                  v-model="compUserUsername"
                  label="Customer Email"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col sm="12" md="4">
                <v-text-field
                  v-model="compUserID"
                  label="Customer ID"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  v-model="dateStartMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newBooking.dateStart"
                      label="Start Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="primary"
                    v-model="newBooking.dateStart"
                    @input="dateStartMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  v-model="dateEndMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newBooking.dateEnd"
                      label="End Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="primary"
                    v-model="newBooking.dateEnd"
                    @input="dateEndMenu = false"
                    :allowed-dates="allowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" cols="4">
                <v-select
                  :items="locations"
                  label="Location"
                  v-model="newBooking.location"
                ></v-select>
              </v-col>
              <v-col sm="12" cols="4">
                <v-select
                  :items="compUnits"
                  label="Unit Number"
                  v-model="newBooking.unit"
                  :item-text="item => item.unitNumber + ' - ' + item.type"
                  item-value="_id"
                ></v-select>
              </v-col>
              <v-col sm="12" cols="4">
                <v-select
                  :items="statusOptions"
                  label="Status"
                  v-model="newBooking.status"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="4">
                <v-text-field
                  label="Payment Amount"
                  v-model="amount"
                  prefix="฿"
                ></v-text-field>
                <!-- v-on:blur="handleBlur" -->
              </v-col>
              <v-col sm="12" md="4">
                <v-select
                  label="Payment Method"
                  v-model="newBooking.paymentMethod"
                  :items="paymentMethods"
                ></v-select>
              </v-col>
              <v-col sm="12" md="4">
                <v-switch
                  v-model="isSubscription"
                  label="Subscription Booking?"
                ></v-switch>
              </v-col>
            </v-row>
            <v-text-field
              v-model="compConfirmation"
              label="Booking No."
              :rules="[rules.required, rules.validBookingNo]"
            ></v-text-field>

            <!-- <v-row v-if="compUserType === 'Corporate'">
              <v-col>
                <v-checkbox
                  v-model="newBooking.wtr"
                  color="primary"
                  required
                  label="Withholding Tax Request"
                >
                </v-checkbox>
              </v-col>
            </v-row> -->

            <v-textarea
              label="Booking Notes"
              v-model="newBooking.notes"
              hint="Booking notes, comments, etc."
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showNewBookingModal = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="saveBooking()" elevation="0">
            Create Booking
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import helpers from "@/plugins/helpers";
const axios = require("axios");
axios.defaults.withCredentials = true;
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";
import TransactionCard from "@/components/transactionCard.vue";
import FancyDate from "@/components/fancyDate.vue";
import { mapGetters } from "vuex";
export default {
  name: "Bookings",
  components: {
    TransactionCard,
    FancyDate,
    VueJsonToCsv
  },
  data: () => ({
    showInactive: false,
    searchCustomer: null,
    searchLocation: "",
    searchLocations: ["ALL", "RAMA 9", "LKB", "LCB"],
    showNewBookingModal: false,
    dateStartMenu: false,
    dateEndMenu: false,
    dateSearchBookingMenu: false,
    dateSearchBooking: [],
    dateSearchEndMenu: false,
    dateSearchEnd: [],
    expanded: [],
    search: "",
    searchOmise: "",
    headers: [
      { text: "Booking No.", value: "confirmation" },
      { text: "Status", value: "status" },
      { text: "Subscription?", value: "isSubscription", align: "center" },
      { text: "Booking Date", value: "createdOn" },
      { text: "Start Date", value: "dateStart" },
      { text: "End Date", value: "dateEnd" },
      { text: "Customer", value: "customerDetails", sortable: false },
      { text: "Unit", value: "unitDetails", sortable: false },
      { text: "Edit", value: "_id", align: "right", sortable: false },
      { text: "Notes", value: "notes", align: "left" },
      { text: "Details", value: "data-table-expand" }
    ],
    valid: true,
    rules: {
      required: value => !!value || "Required.",
      validBookingNo: v => v.length === 15 || "Invalid Booking No."
    },
    dateRange: "",
    dateRanges: [
      "Last 7 Days",
      "Last 30 Days",
      "Last 6 Months",
      "Last 12 Months"
    ],
    url: "",
    author: "",
    editDocumentDialog: false,
    editedDocument: {
      title: "",
      category: "",
      URL: ""
    },
    newBooking: {
      customer: null,
      dateStart: null,
      dateEnd: null,
      unit: [],
      notes: null,
      amount: "0",
      paymentMethod: null,
      confirmation: null
    },
    amount: 0,
    isSubscription: false,
    statusOptions: ["ID required", "Active", "Overdue", "Inactive"],
    locations: ["RAMA 9", "LKB", "LCB"],
    paymentMethods: ["Bank Transfer", "Cash", "EDC"]
    // pricingData: this.$store.state.pricing
  }),
  computed: {
    pricingData() {
      return this.$store.state.pricing;
    },
    dateSearchBookingText() {
      return this.dateSearchBooking.join(" ~ ");
    },
    dateSearchEndText() {
      return this.dateSearchEnd.join(" ~ ");
    },
    ...mapGetters(["bookingsAndCustomers"]),
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    compBookings() {
      let data = this.bookingsAndCustomers;

      // filter out bookings that dont have a unitDetails
      data = data.filter(d => typeof d.unitDetails !== "undefined");

      // hide/show inactive bookings
      if (this.showInactive !== true) {
        data = data.filter(d => d.status !== "Inactive");
      }

      // filter on booking date range
      if (this.dateSearchBooking.length > 0) {
        let results = data.filter(d =>
          this.isWithinDateRange(d.createdOn, this.dateSearchBooking)
        );
        data = results;
      }

      /* // filter on booking date
      if (this.dateSearchBooking.length > 0) {
        let results = data.filter(
          d =>
            moment(d.createdOn).format("DD/MM/YYYY") ===
            moment(this.dateSearchBooking).format("DD/MM/YYYY")
        );
        data = results;
      } */

      // filter on end date
      if (this.dateSearchEnd.length > 0) {
        let results = data.filter(
          d => this.isWithinDateRange(d.dateEnd, this.dateSearchEnd)

          /* d =>
            moment(d.dateEnd).format("DD/MM/YYYY") ===
            moment(this.dateSearchEnd).format("DD/MM/YYYY") */
        );
        data = results;
      }

      // filter on customer name
      if (this.searchCustomer) {
        let results = data.filter(
          d =>
            d.customerDetails.fullname
              .toLowerCase()
              .indexOf(this.searchCustomer.toLowerCase()) >= 0
        );
        data = results;
      }

      // filter on location
      if (this.searchLocation !== "ALL") {
        let results = data.filter(
          d => d.unitDetails.location === this.searchLocation
        );
        data = results;
      }

      // filter on transaction no.
      if (this.searchOmise) {
        let matchingTransactions = [];
        // loop filtered bookings
        for (var i = data.length - 1; i >= 0; i--) {
          // log transaction length
          // console.log(data[i].transactions.length)
          // let isMatch = false;
          // let transactions = data[i].transactions.filter(t => typeof(t._id) !== 'undefined');
          // let transactions = data[i].transactions.filter(t => t._id.indexOf(this.searchOmise) != -1)
          let transactions = data[i].transactions.filter(t => this.isMatch(t));
          if (transactions.length) {
            matchingTransactions.push(data[i]);
          }
        }
        return matchingTransactions;
      }

      // sort by createdOn desc
      data.sort(this.sortDates);

      return data;
    },
    compBookingsCSV() {
      let data = this.compBookings;
      let csv = data.map(b => {
        if (typeof b.customerDetails === "undefined") {
          console.log(
            `Unable to find a matching customer for ${b.confirmation}`
          );
        }
        return {
          _id: b._id,
          status: b.status,
          active: b.active,
          customerID: b.customerDetails ? b.customerDetails._id : "",
          confirmation: b.confirmation,
          unitID: b.unitDetails._id,
          unitType: b.unitDetails.type,
          dateStart: b.dateStart,
          dateEnd: b.dateEnd,
          isSubscription: b.isSubscription,
          subscriptionPrice: this.hideNull(b.subscriptionPrice),
          bookingType: this.calcBookingType(b)

          /* "Booking No.": b.confirmation,
          "Status": b.status,
          "Subscription?": b.isSubscription,
          "Booking Date": this.friendlyDate(b.createdOn),
          "Start Date": this.friendlyDate(b.dateStart),
          "End Date": this.friendlyDate(b.dateEnd),
          "Customer": b.customerDetails.fullname,
          "Unit": b.unitDetails.unitNumber,
          "Notes": b.notes */
        };
      });
      return csv;
    },
    compAnalytics() {
      let data = this.compBookings;
      let analytics = {
        total: data.length,
        subscription: data.filter(b => b.isSubscription).length,
        yearly: data.filter(b => this.subscriptionIsYearly(b)).length,
        halfYearly: data.filter(b => this.subscriptionIsHalfYearly(b)).length,
        monthly: data.filter(b => this.subscriptionIsMonthly(b)).length,
        weekly: data.filter(b => this.subscriptionIsWeekly(b)).length
      };
      return analytics;
    },
    compUsers() {
      let users = this.$store.state.users;
      users.map(u => (u.fullname = u.firstname + " " + u.lastname));
      // only include enabled users
      users = users.filter(u => u.enabled === true);
      // only include customers
      users = users.filter(u => u.role === "customer");
      return users;
    },
    compUnits() {
      let units = this.$store.state.units;

      // remove unavailable units
      units = units.filter(u => u.unitStatus === "Available");

      if (this.newBooking.location) {
        units = units.filter(u => u.location === this.newBooking.location);
      }

      units.sort((a, b) => {
        return a.unitNumber - b.unitNumber;
      });

      return units;
    },
    compConfirmation: {
      get: function() {
        let selectedUnit = "";
        let location = this.newBooking.location ? this.newBooking.location : "";
        if (location === "RAMA 9") {
          location = "RM9";
        }
        let bookingDate = moment().format("DDMMYYYY");
        let unitID = this.newBooking.unit ? this.newBooking.unit : "";
        if (unitID) {
          let unit = this.compUnits.filter(u => u._id === unitID);
          if (unit[0]) {
            return `${location}${bookingDate}${unit[0].unitNumber}`;
          }
        }
        return `${location}${bookingDate}`;
      },
      set: function(value) {
        this.newBooking.confirmation = value;
      }
    },
    compUserUsername() {
      if (this.newBooking.customer) {
        let user = this.compUsers.find(u => u._id === this.newBooking.customer);
        if (user) {
          return user.username;
        }
      }
      return null;
    },
    compUserID() {
      if (this.newBooking.customer) {
        let user = this.compUsers.find(u => u._id === this.newBooking.customer);
        if (user) {
          return user._id;
        }
      }
      return null;
    },
    compUserType() {
      if (this.newBooking.customer) {
        let user = this.compUsers.find(u => u._id === this.newBooking.customer);
        if (user) {
          return user.type;
        }
      }
      return null;
    }
  },
  methods: {
    allowedDates(val) {
      // this function ensures forbidden end dates may not be selected
      const d = parseInt(val.split("-")[2]);
      const disallowed = [29, 30, 31];
      if (disallowed.indexOf(d) === -1) {
        return true;
      }
      return false;
    },
    calcBookingType(b) {
      // if (b.isSubscription) {

      if (this.subscriptionIsYearly(b)) return "Yearly";
      if (this.subscriptionIsHalfYearly(b)) return "Half Yearly";
      if (this.subscriptionIsMonthly(b)) return "Monthly";
      if (this.subscriptionIsWeekly(b)) return "Weekly";
      // }
      // return "Ad Hoc";
    },
    hideNull(d) {
      if (d) return d;
      return "";
    },
    isWithinDateRange(date, range) {
      // single date array
      if (range.length === 1) {
        let start = moment(range[0]).startOf("day");
        let end = moment(range[0]).endOf("day");
        let d = moment(date);
        return d.isBetween(start, end, null, "[]");
      }
      // date range array
      let start = moment(range[0]).startOf("day");
      let end = moment(range[1]).endOf("day");
      let d = moment(date);
      return d.isBetween(start, end, null, "[]");
    },
    findMostRecentObjectByPartialString(arr, partialString) {
      // Filter the array by partial string match
      const filteredObjects = arr.filter(obj =>
        obj.type.includes(partialString)
      );

      // Sort the filtered objects by timestamp, most recent first
      filteredObjects.sort(
        (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
      );

      // Return the most recent object, or undefined if no match was found
      return filteredObjects[0] || undefined;
    },
    subscriptionIsYearly(b) {
      if (b.isSubscription) return false;
      let booking = this.findMostRecentObjectByPartialString(
        b.transactions,
        "Extended"
      );
      if (!booking)
        booking = this.findMostRecentObjectByPartialString(
          b.transactions,
          "Booking created"
        );
      if (!booking) return false;
      let dateStart = moment(booking.dateStart);
      let dateEnd = moment(booking.dateEnd);
      let duration = moment.duration(dateEnd.diff(dateStart));
      let years = duration.asYears();
      if (years >= 0.9) {
        return true;
      }
      return false;
    },
    subscriptionIsHalfYearly(b) {
      if (b.isSubscription) return false;
      //console.log(`booking ${b.confirmation} is subscription: ${b.isSubscription}`);
      //if (b.isSubscription===false) return false;
      let booking = this.findMostRecentObjectByPartialString(
        b.transactions,
        "Extended"
      );
      if (!booking)
        booking = this.findMostRecentObjectByPartialString(
          b.transactions,
          "Booking created"
        );
      if (!booking) return false;

      let dateStart = moment(booking.dateStart);
      let dateEnd = moment(booking.dateEnd);
      let duration = moment.duration(dateEnd.diff(dateStart));
      let months = duration.asMonths();
      if (months >= 5.9 && months < 11) {
        return true;
      }
      return false;
    },
    subscriptionIsMonthly(b) {
      // console.log(b.confirmation);
      if (b.isSubscription) return false;
      // console.log(b.confirmation);
      let booking = this.findMostRecentObjectByPartialString(
        b.transactions,
        "Extended"
      );
      if (!booking)
        booking = this.findMostRecentObjectByPartialString(
          b.transactions,
          "Booking created"
        );
      if (!booking) return false;
      let dateStart = moment(booking.dateStart);
      let dateEnd = moment(booking.dateEnd);
      let duration = moment.duration(dateEnd.diff(dateStart));
      let months = duration.asMonths();
      //console.log(`booking ${b.confirmation} monthly: ${months}`);
      if (months >= 0.9 && months < 2) {
        return true;
      }
      return false;
    },
    subscriptionIsWeekly(b) {
      if (b.isSubscription) return false;

      //if (!b.isSubscription) return false;
      let booking = this.findMostRecentObjectByPartialString(
        b.transactions,
        "Extended"
      );
      //console.log(booking);
      if (!booking)
        booking = this.findMostRecentObjectByPartialString(
          b.transactions,
          "Booking created"
        );
      if (!booking) return false;
      let dateStart = moment(booking.dateStart).startOf("day");
      let dateEnd = moment(booking.dateEnd);
      let duration = moment.duration(dateEnd.diff(dateStart));
      let weeks = duration.asWeeks();
      //console.log(`booking ${b.confirmation} weeks: ${weeks}`);
      if (weeks >= 1 && weeks < 2) {
        return true;
      }
      return false;
    },
    // handleBlur(){
    //     if(this.newBooking.amount) {
    //         this.newBooking.amount = Number(this.newBooking.amount.replace(/[^\d.]/g, '')).toLocaleString('en-US', {
    //           minimumFractionDigits: 2,
    //           maximumFractionDigits: 2
    //         });
    //     }
    // },
    sortDates(a, b) {
      // console.log('p')
      // console.log(moment(a.createdOn).isBefore(moment(b.createdOn)))
      // Sort dates
      return moment(a.createdOn).isBefore(moment(b.createdOn));
      // return a._id + b._id
    },
    isMatch(t) {
      if (
        typeof t._id !== "undefined" &&
        t._id !== null &&
        t._id.indexOf(this.searchOmise) != -1
      )
        return true;
      return false;
    },
    showToast() {
      this.$toasted
        .show("Thanks! ", {
          type: "success",
          icon: "mdi-thumb-up"
        })
        .goAway(12500);
    },
    emitShowEditBookingModal(booking) {
      this.$emit("showEditBookingModal", booking);
    },
    saveBooking() {
      let vm = this;
      let booking = vm.newBooking;
      // REMOVED FOR ADMIN ADD BOOKINGS:
      booking.confirmation = booking.confirmation
        ? booking.confirmation
        : vm.compConfirmation;

      // subscription pricing UI
      booking.amount = vm.amount;
      booking.isSubscription = vm.isSubscription;

      // DEV
      // console.log(booking);

      // SEND BOOKING TO API
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .post(vm.$root.urls.api + "/v3/adminAddBooking", { booking })
        .then(function(response) {
          // handle success
          // update booking store
          let newBooking = response.data;
          vm.$store.commit("addBooking", newBooking);
          // update unit store
          let updatedUnit = {
            _id: vm.newBooking.unit,
            unitStatus: "Booked"
          };
          // console.log(updatedUnit);
          vm.$store.commit("reserveUnit", updatedUnit);

          // show friendly message
          vm.$toasted
            .show("New booking created ", {
              type: "success",
              icon: "mdi-thumb-up"
            })
            .goAway(2500);

          // close modal
          vm.showNewBookingModal = false;

          // reset modal form
          vm.newBooking = {
            customer: null,
            dateStart: null,
            dateEnd: null,
            unit: [],
            notes: null,
            // amount: null,
            paymentMethod: null,
            // isSubscription: false,
            confirmation: null
          };
          // reset data
          vm.amount = 0;
          vm.isSubscription = false;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
          vm.$toasted
            .show("Unable to complete this booking ", {
              type: "error",
              icon: "mdi-alert-circle"
            })
            .goAway(2500);
        })
        .then(function() {
          // always executed
        });
    },
    setSubscriptionPrice() {
      // show a warning if location and unit type have not been selected
      if (this.newBooking.location && this.newBooking.unit) {
        // get unit type
        let unitID = this.newBooking.unit;
        let unit = this.compUnits.find(u => u._id === unitID);

        // this.newBooking.amount = '666';
        const price = this.pricingData.find(p => {
          if (p.location === this.newBooking.location && p.type === unit.type)
            return true;
        });
        // delete this.newBooking.amount
        this.amount = String(price.priceSubscription);
        // this.$set(this.newBooking, 'amount', String(price.priceSubscription))
      } else {
        this.$toasted
          .show("Please select a location and unit type ", {
            type: "error",
            icon: "mdi-thumb-down"
          })
          .goAway(3000);
      }
    },
    friendlyDate(d) {
      return helpers.formatFriendlyDate(d);
    }
    /* getPricing() {
      //v3 get unit data
      let vm = this;
      return axios
        .get(vm.$root.urls.api + "/v3/prices", {})
        .then(function(response) {
          var pricing = response.data;
          vm.pricingData = pricing;
        });
    } */
  },
  mounted: function() {
    // listen for booking confirmation parameter
    if (this.$route.params.confirmation) {
      this.searchLocation = "ALL";
      this.search = this.$route.params.confirmation;
      let details = this.bookingsAndCustomers.filter(
        b => b.confirmation === this.$route.params.confirmation
      );
      // console.log(details);
      this.expanded.push(details[0]);
      // this.expanded.push(this.bookingsAndCustomers[this.bookingsAndCustomers.length-1])
    }
    // get pricing data
    // this.getPricing();
  },
  watch: {
    isSubscription(val) {
      if (val) {
        // console.log(val);
        // console.log("calling set subscription price");
        this.setSubscriptionPrice();
      }
    }
    // newBooking: {
    //   handler(val) {
    //     if (val.isSubscription) {
    //         console.log('calling set subscription price')
    //         this.setSubscriptionPrice()
    //     }
    //   },
    //   deep: true
    // }
  }
};
</script>
